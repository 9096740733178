import React, { Fragment, memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import GNBLayout from 'components/layouts/GNB'
import UserLayout from 'components/layouts/user'
import { Spinner } from 'components/elements/Spinner'
import { setAlert } from 'store/default'
import { colors, spaces } from 'libs/theme'
import { storeItems, mainItems, heights } from 'libs/items'
import { getUrlQuery, makeComma } from 'libs/string'
import { doWithTagManager } from 'libs/local'

import { Grid, Text } from 'components/elements'
import { Button } from 'components/elements/Buttons'
import Icons from 'components/elements/Icons'

import Popup from 'components/commons/Popup'
import ThemeProgramList from '../components/routine/program/ThemeProgramList'
import ThemeRoutineList from '../components/routine/program/ThemeRoutineList'
import ThemeArea from '../components/routine/program/ThemeArea'
import { Pagination, Autoplay, FreeMode } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import NextImage from '../components/commons/NextImage'
import styled from 'styled-components'

const SwiperPagination = styled.div`
  text-align: center;
  .swiper-pagination-bullet{
    margin: 0 2px 0 2px;
  }
  .swiper-pagination-bullet-active {
    background-color: #002587 !important
  }
`

const Main = memo(() => {
  const router = useRouter()
  const dispatch = useDispatch()
  const q = getUrlQuery(router.asPath)
  const { breakpoints, safeAreaInsets, env, banner, user } = useSelector(state => state.default)

  const [isInit, setisInit] = useState(false)
  const [width, setwidth] = useState()
  const [subjectList, setsubjectList] = useState()
  const [subjectMain, setsubjectMain] = useState()
  const [habbitInterviewList, setHabbitInterviewList] = useState([])
  const [banners, setbanners] = useState()
  const [popup, setpopup] = useState()
  const [isopenPopup, setisopenPopup] = useState(true)

  const [programList, setProgramList] = useState([])
  const [routineList, setRoutineList] = useState([])

  const [isBannerLoading, setIsBannerLoading] = useState(false)
  const [wiseSaying, setWiseSaying] = useState()

  const getRandomWiseSaying = () => {
    const images = [
      'wise_saying1',
      'wise_saying2',
      'wise_saying3',
      'wise_saying4',
      'wise_saying5',
      'wise_saying6'
    ]
    const randomIndex = Math.floor(Math.random() * images.length)
    const randomImage = images[randomIndex]
    setWiseSaying(randomImage)
  }
  const getJournals = async (tag, size = 3) => {
    const { success, data } = await window.api.request('get', 'journal', { tags: [window.encodeURIComponent(tag)], page: 1, page_size: size })
    if (success) return data.results
  }

  const getBanners = async () => {
    setIsBannerLoading(true)
    const { success, data } = await window.api.request('get', 'banners')
    if (success) {
      setbanners(data.results.filter(r => r.subtitle === mainItems.banner.slide))
      setpopup(data.results.find(r => r.subtitle === mainItems.banner.popup))
      setIsBannerLoading(false)
    } else {
      setIsBannerLoading(false)
    }
  }

  const checkAuth = () => {
    if (q.check_auth) {
      router.push('/')
      if (!user.is_identity_verified) {
        dispatch(setAlert({ body: '활동을 위해 본인인증이 필요합니다.<br />본인인증을 진행해주세요.' }))
        let redirectUrl = ''
        if (q.redirect_url && q.redirect_url === 'routine') {
          redirectUrl = '?auth_redirect=routine'
        } else if (q.redirect_url && q.redirect_url === 'onboarding') {
          redirectUrl = '?auth_redirect=onboarding'
        }
        router.push(`/sir${redirectUrl}`)
      }
    }
  }

  useEffect(() => {
    if (user) checkAuth()
  }, [user])

  useEffect(async () => {
    setsubjectMain(await getJournals('#홈_저널_배너', storeItems.journalTags.subjectMain.size))
    setsubjectList(await getJournals('#홈_저널_리스트', storeItems.journalTags.subjectList.size))
    setHabbitInterviewList(await getJournals('홈_인터뷰', 10))
    setProgramList(await getProgramList('홈_인기_프로그램'))
    setRoutineList(await getRoutineList('홈_인기_루틴'))
    getBanners()
    getRandomWiseSaying()
  }, [])

  useEffect(() => {
    if (breakpoints) setwidth(breakpoints.width)
  }, [breakpoints])

  useEffect(() => {
    if (!isInit && env) {
      setisInit(true)
    }
  }, [env])

  const getProgramList = async (tag) => {
    const { success, data } = await window.api.request('get', 'program', { page_size: 99999, tags: [tag], ordering: '-n_participants' })
    if (success) return data.results
  }

  const getRoutineList = async (tag) => {
    const { success, data } = await window.api.request('get', 'routine', { page_size: 99999, tags: [tag], ordering: '-n_participants' })
    if (success) return data.results
  }

  const movePage = (value) => {
    router.push(value)
  }

  return (
    <GNBLayout isFooter isBanner headerBackgroundColor={colors.primary.dark_new} headerColor={colors.white}>
      <UserLayout>
        {
          !!popup && <Popup id={popup.id} isOpen={isopenPopup} image={popup.image} mImage={popup.image_mobile} close={() => setisopenPopup(false)} />
        }
        <Grid
          width='100%'
          height={breakpoints?.isIos && safeAreaInsets ? `${Number(safeAreaInsets.top) + Number(heights.header)}px` : `${heights.header}px`}
          color={colors.primary.dark_new}
        />
        <div style={{ marginTop: banner ? 40 : 0 }}>
          <Grid padding={`${spaces.contents.s3.px} ${spaces.default.px} 60px`} color={colors.primary.dark_new}>
            {
              !!user &&
                <Grid gap={spaces.component.s4.px} margin='0 0 50px 0'>
                  <Text color={colors.white} variant='title1' family='Pretendard Variable'>
                    {user.name}님,<br />
                    오늘도 건강한 습관을 통해,<br />
                    <Text color={colors.point} variant='title1' family='Pretendard Variable'>멋진 남성</Text>으로 성장해봅시다!
                  </Text>
                </Grid>
            }
            {
              !user &&
                <Grid gap={spaces.component.s4.px} margin='0 0 50px 0'>
                  <Text color={colors.white} variant='title1' family='Pretendard Variable'>
                    건강한 습관, 건강한 삶.<br />
                    <Text color={colors.point} variant='title1' family='Pretendard Variable'>멋진 남성</Text>으로 거듭나세요.
                  </Text>
                  <Button variant='text' width='fit-content' padding='0' radius='0' onClick={() => router.push('/account')}>
                    <Grid columns='1fr 18px' gap={spaces.component.s2.px} align='center'>
                      <Text color={colors.white} variant='caption2' family='Pretendard Variable'>로그인하기</Text>
                      <Icons icon='arrow-curved' width='18' height='18' color={colors.white} rotate='90' />
                    </Grid>
                  </Button>
                </Grid>
            }
            <Grid position='relative'>
              <Grid marginTop='44px' columns='1fr 1fr' rows='120px' gap={spaces.component.s4.px} position='absolute' style={{ top: '-60px', right: '0px', left: '0px', bottom: '0px', zIndex: 1 }}>
                <Button color={colors.white} radius='8px' justifyContent='start' height='100%'
                      onClick={() => router.push('/routine/onboarding')} style={{ boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.05)' }}>
                  <Grid gap={spaces.component.s2.px} width='100%' height='120px' columns='1fr' rows='1fr'>
                    <Grid gap={spaces.component.s2.px} padding={spaces.component.s4.px} align='start' image='/images/main/main-icon-ring.png' imageSize='36px' imagePosition='90% 80%'>
                      <Text variant='body1' color={colors.grey.g800} align='left' family='Pretendard Variable'>건강 루틴</Text>
                      <Text variant='caption2' color={colors.grey.g700} align='left' family='Pretendard Variable'>추천받기</Text>
                    </Grid>
                  </Grid>
                </Button>
                <Button color={colors.white} radius='8px' justifyContent='start' height='100%'
                      onClick={() => router.push('/me/reservation?page=1&tab=list')} style={{ boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.05)' }}>
                  <Grid gap={spaces.component.s2.px} width='100%' height='120px' columns='1fr' rows='1fr'>
                    <Grid gap={spaces.component.s2.px} padding={spaces.component.s4.px} align='start'>
                      <Text variant='body1' color={colors.grey.g600} align='left' family='Pretendard Variable'>비대면 진료가<br />종료되었어요.</Text>
                      <Text variant='caption2' color={colors.grey.g700} align='left' family='Pretendard Variable'>진료기록 보기</Text>
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
            </Grid>

          </Grid>
          <Grid position='relative'>
            <Grid position='absolute' color={colors.white} height='40px' width='100%' borderRadius='20px' style={{ top: '-18px' }} />
          </Grid>

          <Grid marginTop='90px' width='100%' height='94px' justify='center' display='block'>
            {
              !isBannerLoading && !!banners && banners.length > 0
                ? (
                  <>
                    <Swiper
                      className='main_banner_swiper'
                      slidesPerView='auto'
                      centeredSlides
                      grabCursor
                      loopedSlides={2}
                      loop
                      modules={[Autoplay, Pagination]}
                      pagination={{
                        el: '.main_banner_swiper_pagination',
                        clickable: true
                      }}
                      autoplay={{
                        delay: 2500
                      }}
                      style={{
                        width: '100%',
                        height: '94px'
                      }}
                    >
                      {
                        banners.map((b, index) => (
                          <SwiperSlide
                            key={b.id}
                            style={{
                              width: '320px',
                              textAlign: 'center'
                            }}
                          >
                            <NextImage
                              src={b.image}
                              width='300px'
                              height='94px'
                              borderRadius='12px'
                              onClick={() => doWithTagManager(`gtm_click_home_banner_${index + 1}`, () => router.push(b.link))}
                            />
                          </SwiperSlide>
                        ))
                      }
                    </Swiper>
                    <SwiperPagination
                      style={{
                        textAlign: 'center',
                        marginTop: '5px'
                      }}
                      className='main_banner_swiper_pagination'
                    />
                  </>
                  )
                : (
                  <Grid justify='center' align='center'>
                    <Spinner color={colors.primary.dark} />
                  </Grid>
                  )
            }
          </Grid>
          <Grid marginTop='64px' padding={`0 ${spaces.default.px}`}>
            <Text
              variant='title3'
              color={colors.grey.g800}
              family='Pretendard Variable'
            >
              썰즈 마스터 / 엑스퍼트 <Text variant='title3' color={colors.primary.light} family='Pretendard Variable'>인터뷰</Text>
            </Text>
            <Grid marginTop='16px' display='block' overflow='hidden'>
              <Swiper
                className='main_habbit_interview_swiper'
                grabCursor
                freeMode
                slidesPerView='auto'
                modules={[FreeMode]}
                style={{
                  width: '100%'
                }}
              >
                {
                  habbitInterviewList && habbitInterviewList.length > 0 && habbitInterviewList.map(habbitInterview => {
                    return (
                      <SwiperSlide
                        key={habbitInterview.id}
                        style={{ width: '290px' }}
                      >
                        <NextImage
                          src={habbitInterview?.image}
                          width='280px'
                          height='360px'
                          borderRadius='12px'
                          onClick={() => movePage('/community/journal/' + habbitInterview.id)}
                        />
                      </SwiperSlide>
                    )
                  })
                }
              </Swiper>
            </Grid>
          </Grid>
          <Grid
            padding={`0 ${spaces.default.px}`}
            margin='16px 0 64px 0'
            justify='center'
          >
            <NextImage
              src={wiseSaying ? `/images/main_new/${wiseSaying}.png` : null}
              width='626px'
              height='230px'
            />
          </Grid>
          <ThemeArea
            title={(
              <Text
                variant='title3'
                color={colors.grey.g800}
                family='Pretendard Variable'
              >
                건강하고 멋진 남성이 되는 <Text variant='title3' color={colors.primary.light} family='Pretendard Variable'>루틴</Text>
              </Text>
            )}
          >
            <ThemeProgramList list={programList} subTitle='인기 프로그램' />
            <Grid
              style={{ marginTop: '10px' }}
            >
              <ThemeRoutineList list={routineList} subTitle='인기 루틴' />
            </Grid>
          </ThemeArea>
          <Grid marginTop='64px' padding={`0 ${spaces.default.px}`} align='end' display='flex' justify='space-between'>
            <Text variant='title3' color={colors.grey.g800} family='Pretendard Variable'>썰즈 <Text variant='title3' color={colors.primary.light} family='Pretendard Variable'>메디컬 서비스</Text></Text>
          </Grid>
          <Grid marginTop='16px' gap='10px' columns='1fr 1fr 1fr' padding={`0 ${spaces.default.px}`}>
            <Grid
              width='100%'
            >
              <NextImage
                src='/images/main_new/main-icon-book.png'
                width='300px'
                height='300px'
                onClick={() => movePage('/community/journal')}
              />
            </Grid>
            <Grid
              width='100%'
            >
              <Grid>
                <NextImage
                  src='/images/main_new/main-icon-building.png'
                  width='300px'
                  height='300px'
                  onClick={() => movePage('/doctor/treatment?d1=hair&d2=implant')}
                />
              </Grid>
            </Grid>
            <Grid
              width='100%'
            >
              <NextImage
                src='/images/main_new/main-icon-question.png'
                width='300px'
                height='300px'
                onClick={() => movePage('/doctor/qna')}
              />
            </Grid>
          </Grid>
        </div>
        {
          !!subjectMain && subjectMain.length > 0 && (
            <Grid marginTop={spaces.component.s4.px} display='block' width='100%' position='relative'>
              <Grid marginTop='64px' padding={`0 ${spaces.default.px}`} align='end' display='flex' justify='space-between'>
                <Text
                  variant='title3'
                  color={colors.grey.g800}
                  family='Pretendard Variable'
                >
                  남성 두피관리를 위한 <Text variant='title3' color={colors.primary.light} family='Pretendard Variable'>저널</Text>
                </Text>
                <Button padding='0' radius='0' onClick={() => router.push('/community/journal?category=hair')}>
                  <Text variant='caption1' color={colors.grey.g700} family='Pretendard Variable'>더보기</Text>
                </Button>
              </Grid>
              <Grid marginTop='16px' padding={`0 ${spaces.default.px}`}>
                <Grid position='relative'>
                  <NextImage
                    src={subjectMain[0].image}
                    width='1270px'
                    height='600px'
                    borderRadius='12px'
                    onClick={
                      () => doWithTagManager('gtm_click_home_journal', () => router.push(`/community/journal/${subjectMain[0].id}`))
                    }
                  />
                  <Grid position='absolute' gap={spaces.component.s3.px} style={{ top: 20, left: 20 }}>
                    <Text variant='caption2' color={colors.white} ellipsis='1' align='left'>의학 저널</Text>
                    <Text width='180px' variant='title3' color={colors.white} ellipsis='3' align='left' html={subjectMain[0]?.title?.replaceAll('\\n', '<br>')} />
                  </Grid>
                </Grid>
                {/* <Swiper
                  className='main_journal_swiper'
                  grabCursor
                  freeMode
                  slidesPerView='auto'
                  modules={[FreeMode]}
                  style={{
                    width: '100%'
                  }}
                >
                  {
                  subjectMain.map((s, index) => (
                    <SwiperSlide key={s.id}>
                      <Button
                        padding='0'
                        overflow='hidden'
                        onClick={() => doWithTagManager(`gtm_click_home_journal_list_${index}`, () => router.push(`/community/journal/${s.id}`))}>
                        <Grid image={s.image} height='132px' padding={spaces.contents.s1.px} gap={spaces.component.s3.px} align='start'>
                          <Text variant='subtitle1' color={colors.white} ellipsis='2' align='left' family='Pretendard Variable'>{s.title}</Text>
                          <Text variant='caption3' color={colors.white} ellipsis='2' align='left' family='Pretendard Variable'>
                            {s.new_tags.filter(t => t.indexOf('#') === -1).map(t => `#${t}`).join(' ')}
                          </Text>
                        </Grid>
                      </Button>
                    </SwiperSlide>
                  ))
                }
                </Swiper> */}
              </Grid>
            </Grid>
          )
        }
        {
          !!subjectList && subjectList.length > 0 && (
            <Grid padding={`0 ${spaces.default.px}`} marginTop='8px' gap={spaces.component.s2.px}>
              {
                subjectList.map((l, index) => (
                  <Fragment key={l.id}>
                    <Button
                      padding='0'
                      radius='0'
                      onClick={
                        () => doWithTagManager(`gtm_click_home_journal_list_${index}`, () => router.push(`/community/journal/${l.id}`))
                      }
                    >
                      <Grid columns='350px 1fr' width='100%' height='58px' gap='15px' align='center'>
                        <Grid width='100%' align='center' height='36px'>
                          <Text variant='body2' color={colors.grey.g800} align='left' ellipsis='1' family='Pretendard Variable' height='20px'>{l?.title?.replaceAll('\\n', '<br>')}</Text>
                          <Text variant='caption3' color={colors.grey.g700} align='left' ellipsis='1' family='Pretendard Variable' height='16px' marginTop='4px'>{l.new_tags.filter(t => t.indexOf('#') === -1).map(t => `#${t}`).join(' ')}</Text>
                        </Grid>
                        <Grid align='center'>
                          <Icons icon='right-arrow' width='9' height='16' color={colors.grey.g700} />
                        </Grid>
                      </Grid>
                    </Button>
                    <Grid height='1px' width='100%' color={colors.grey.g200} />
                  </Fragment>
                ))
              }
            </Grid>
          )
        }
      </UserLayout>
    </GNBLayout>
  )
}, () => {
  return true
})

export default Main
